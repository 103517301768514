import {Slide} from '@/types/slides'

export const slides: Slide[] = [
  {
    id: 'test-slide-1',
    elements: [],
    background: {
      type: 'solid',
      color: '#ffffff',
    },
  }
]