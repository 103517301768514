import {storeToRefs} from 'pinia'
import {useMainStore} from '@/store'
import {copyText, readClipboard} from '@/utils/clipboard'
import {encrypt} from '@/utils/crypto'
import {message} from 'ant-design-vue'
import usePasteTextClipboardData from '@/hooks/usePasteTextClipboardData'
import useDeleteElement from './useDeleteElement'

export default () => {
  const mainStore = useMainStore()
  const {activeElementIdList, activeElementList} = storeToRefs(mainStore)

  const {pasteTextClipboardData} = usePasteTextClipboardData()
  const {deleteElement} = useDeleteElement()

  // 将选中元素数据加密后复制到剪贴板
  const copyElement = () => {
    if (!activeElementIdList.value.length) return

    const text = encrypt(JSON.stringify({
      type: 'elements',
      data: activeElementList.value,
    }))

    copyText(text).then(() => {
      mainStore.setEditorareaFocus(true)
    })
  }

  // 将选中元素复制后删除（剪切）
  const cutElement = () => {
    copyElement()
    deleteElement()
  }

  // 尝试将剪贴板元素数据解密后进行粘贴
  const pasteElement = () => {
    console.log('尝试将剪贴板元素数据解密后进行粘贴')
    readClipboard().then(text => {
      pasteTextClipboardData(text)
    }).catch(err => message.warning(err))
  }

  // 将选中元素复制后立刻粘贴
  const quickCopyElement = () => {
    copyElement()
    pasteElement()
  }

  return {
    copyElement,
    cutElement,
    pasteElement,
    quickCopyElement,
  }
}