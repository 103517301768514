import {customAlphabet} from 'nanoid'
import {defineStore} from 'pinia'
import {CreatingElement, TextFormatPainter} from '@/types/edit'
import {ToolbarStates} from '@/types/toolbar'
import {DialogForExportTypes} from '@/types/export'
import {SYS_FONTS} from '@/configs/font'
import {TextAttrs, defaultRichTextAttrs} from '@/utils/prosemirror/utils'
import {isSupportFont} from '@/utils/font'

import {useSlidesStore} from './slides'
import {Routes} from '@/types/routes'
import {SYS_ROUTE} from '@/configs/routes'
import Cookies from 'js-cookie'

export interface MainState {
  activeElementIdList: string[]
  handleElementId: string
  activeGroupElementId: string
  hiddenElementIdList: string[]
  canvasPercentage: number
  canvasScale: number
  canvasDragged: boolean
  thumbnailsFocus: boolean
  editorAreaFocus: boolean
  disableHotkeys: boolean
  gridLineSize: number
  showRuler: boolean
  creatingElement: CreatingElement | null
  availableFonts: typeof SYS_FONTS
  toolbarState: ToolbarStates
  clipingImageElementId: string
  isScaling: boolean
  richTextAttrs: TextAttrs
  selectedTableCells: string[]
  selectedSlidesIndex: number[]
  dialogForExport: DialogForExportTypes
  databaseId: string
  textFormatPainter: TextFormatPainter | null
  showSelectPanel: boolean
  showSelectDocumentList: boolean
  isLogin: boolean
  isLoginDialog: boolean
  indexShow: string
  routes: Routes[]
  webViewUrl: string
  webViewType: string
  messageType: string
  isSearchBox: boolean
  isFavorite: boolean
  userInfo: any
  isAutoSave: boolean
  historySnapshotCount: number
}

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
export const databaseId = nanoid(10)

export const useMainStore = defineStore('main', {
  state: (): MainState => ({
    activeElementIdList: [], // 被选中的元素ID集合，包含 handleElementId
    handleElementId: '', // 正在操作的元素ID
    activeGroupElementId: '', // 组合元素成员中，被选中可独立操作的元素ID
    hiddenElementIdList: [], // 被隐藏的元素ID集合
    canvasPercentage: 80, // 画布可视区域百分比
    canvasScale: 1.6, // 画布缩放比例（基于宽度1000px）
    canvasDragged: false, // 画布被拖拽移动
    thumbnailsFocus: false, // 左侧导航缩略图区域聚焦
    editorAreaFocus: false, //  编辑区域聚焦
    disableHotkeys: false, // 禁用快捷键
    gridLineSize: 0, // 网格线尺寸（0表示不显示网格线）
    showRuler: false, // 显示标尺
    creatingElement: null, // 正在插入的元素信息，需要通过绘制插入的元素（文字、形状、线条）
    availableFonts: SYS_FONTS, // 当前环境可用字体
    toolbarState: ToolbarStates.SLIDE_DESIGN, // 右侧工具栏状态
    clipingImageElementId: '', // 当前正在裁剪的图片ID  
    richTextAttrs: defaultRichTextAttrs, // 富文本状态
    selectedTableCells: [], // 选中的表格单元格
    isScaling: false, // 正在进行元素缩放
    selectedSlidesIndex: [], // 当前被选中的页面索引集合
    dialogForExport: '', // 导出面板
    databaseId, // 标识当前应用的indexedDB数据库ID
    textFormatPainter: null, // 文字格式刷
    showSelectPanel: false, // 打开选择面板
    showSelectDocumentList: true, // 打开数据选择面板
    isLogin: !!Cookies.get('session_key'), // 登录状态，默认未登录
    isLoginDialog: false, // 登录面板 默认关闭
    indexShow: 'index', // 首页展示页面 默认index首页 preview预览页 personal个人中心 webview灵感图库打开的页面
    routes: SYS_ROUTE,
    webViewUrl: '',
    webViewType: '',
    messageType: '', // 消息类型
    isSearchBox: true, // 默认false滚动条到顶部的时候搜索框不显示，true显示搜索框
    isFavorite: false, // 搜藏框默认不打开false，打开为true
    userInfo: (Cookies.get('userinfo') !== undefined && Cookies.get('userinfo') !== 'undefined' && Cookies.get('userinfo') !== '' && Cookies.get('userinfo') !== null) ? JSON.parse(Cookies.get('userinfo') + '') : null, // 登录后的用户信息
    isAutoSave: true, // 自动保存 默认打开自动保存true 手动保存 false
    historySnapshotCount: 0 // 历史保存次数 默认0 没变动一次+1
  }),

  getters: {
    activeElementList(state) {
      const slidesStore = useSlidesStore()
      const currentSlide = slidesStore.currentSlide
      if (!currentSlide || !currentSlide.elements) return []
      return currentSlide.elements.filter(element => state.activeElementIdList.includes(element.id))
    },

    handleElement(state) {
      const slidesStore = useSlidesStore()
      const currentSlide = slidesStore.currentSlide
      if (!currentSlide || !currentSlide.elements) return null
      // console.log('handleElement', currentSlide.elements.find(element => state.handleElementId === element.id))
      return currentSlide.elements.find(element => state.handleElementId === element.id) || null
    },
  },

  actions: {
    setHistorySnapshotCount() {
      this.historySnapshotCount++
    },
    setUserInfo(info: any) {
      this.userInfo = info
    },
    setIsFavorite(isfa: boolean) {
      this.isFavorite = isfa
    },
    setIsSearchBox(isSB: boolean) {
      this.isSearchBox = isSB
    },
    setMessageType(type: string) {
      this.messageType = type
    },
    setWebViewType(type: string) {
      this.webViewType = type
      if (this.indexShow === 'index') {
        this.isSearchBox = false
      }
      else {
        this.isSearchBox = true
      }
    },
    setWebViewUrl(url: string) {
      this.webViewUrl = url
    },
    setIndexShow(page: string) {
      this.indexShow = page
    },
    setIsLoginDialog(isld: boolean) {
      this.isLoginDialog = isld
    },
    setLoginStatus(isLo: boolean) {
      // console.log(isLo)
      this.isLogin = isLo
    },
    setShowSelectDocumentList(isDocument: boolean) {
      this.showSelectDocumentList = isDocument
    },
    setActiveElementIdList(activeElementIdList: string[]) {
      if (activeElementIdList.length === 1) {
        this.handleElementId = activeElementIdList[0]
      }
      else {
        this.handleElementId = ''
      }

      this.activeElementIdList = activeElementIdList
    },

    setHandleElementId(handleElementId: string) {
      this.handleElementId = handleElementId
    },

    setActiveGroupElementId(activeGroupElementId: string) {
      this.activeGroupElementId = activeGroupElementId
    },

    setHiddenElementIdList(hiddenElementIdList: string[]) {
      this.hiddenElementIdList = hiddenElementIdList
    },

    setCanvasPercentage(percentage: number) {
      this.canvasPercentage = percentage
    },

    setCanvasScale(scale: number) {
      this.canvasScale = scale
    },

    setCanvasDragged(isDragged: boolean) {
      this.canvasDragged = isDragged
    },

    setThumbnailsFocus(isFocus: boolean) {
      this.thumbnailsFocus = isFocus
    },

    setEditorareaFocus(isFocus: boolean) {
      this.editorAreaFocus = isFocus
    },

    setDisableHotkeysState(disable: boolean) {
      this.disableHotkeys = disable
    },

    setGridLineSize(size: number) {
      this.gridLineSize = size
    },

    setRulerState(show: boolean) {
      this.showRuler = show
    },

    setCreatingElement(element: CreatingElement | null) {
      this.creatingElement = element
    },

    setAvailableFonts() {
      this.availableFonts = SYS_FONTS.filter(font => isSupportFont(font.value))
    },

    setToolbarState(toolbarState: ToolbarStates) {
      this.toolbarState = toolbarState
    },

    setClipingImageElementId(elId: string) {
      this.clipingImageElementId = elId
    },

    setRichtextAttrs(attrs: TextAttrs) {
      this.richTextAttrs = attrs
    },

    setSelectedTableCells(cells: string[]) {
      this.selectedTableCells = cells
    },

    setScalingState(isScaling: boolean) {
      this.isScaling = isScaling
    },

    updateSelectedSlidesIndex(selectedSlidesIndex: number[]) {
      this.selectedSlidesIndex = selectedSlidesIndex
    },

    setDialogForExport(type: DialogForExportTypes) {
      this.dialogForExport = type
    },

    setTextFormatPainter(textFormatPainter: TextFormatPainter | null) {
      this.textFormatPainter = textFormatPainter
    },

    setSelectPanelState(show: boolean) {
      this.showSelectPanel = show
    },
  },
})