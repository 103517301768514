import {debounce, throttle} from 'lodash'
import {useMainStore, useSnapshotStore} from '@/store'
import useSaveElement from '@/hooks/useDocument'
import {storeToRefs} from 'pinia'

export default () => {
  const mainStore = useMainStore()
  const snapshotStore = useSnapshotStore()
  const {saveDocument} = useSaveElement()
  const {isAutoSave} = storeToRefs(mainStore)

  // 添加历史快照(历史记录)
  const addHistorySnapshot = debounce(function() {
    snapshotStore.addSnapshot()
    mainStore.setHistorySnapshotCount()
    if (isAutoSave.value) {
      saveDocument(false)
    }
  }, 300, {trailing: true})

  // 重做
  const redo = throttle(function() {
    snapshotStore.reDo()
    mainStore.setHistorySnapshotCount()
  }, 100, {leading: true, trailing: false})

  // 撤销
  const undo = throttle(function() {
    snapshotStore.unDo()
    mainStore.setHistorySnapshotCount()
  }, 100, {leading: true, trailing: false})

  return {
    addHistorySnapshot,
    redo,
    undo,
  }
}