import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Editor from '@/views/Editor/index.vue'
import Mobile from '@/views/Mobile/index.vue'
import DocumentList from '@/views/Editor/DocumentList.vue'
import ColorCenter from '@/views/Editor/HomePage/ColorCenter.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:id',
    name: 'Editor',
    component: Editor
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: Mobile
  },
  {
    path: '/',
    name: 'DocumentList',
    component: DocumentList
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 全局导航守卫示例
router.beforeEach((to, from, next) => {
  // 在这里可以进行路由跳转前的一些逻辑判断
  next()
})

export default router
