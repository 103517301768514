import {Routes} from '@/types/routes'

export const SYS_ROUTE: Routes[] = [
  {
    path: '',
    breadcrumbName: '企划中心',
    children: [{
      path: '',
      breadcrumbName: '品牌企划',
    }, {
      path: '',
      breadcrumbName: '设计企划',
    }, {
      path: '',
      breadcrumbName: '商品企划',
    }, {
      path: '',
      breadcrumbName: '图案企划',
    }]
  }, {
    path: '',
    breadcrumbName: '款式中心',
    children: [{
      path: '',
      breadcrumbName: '品牌',
    }, {
      path: '',
      breadcrumbName: 'T台',
    }, {
      path: '',
      breadcrumbName: '时尚合辑',
    }, {
      path: '',
      breadcrumbName: '城市街拍',
    }, {
      path: '',
      breadcrumbName: '会展',
    }, {
      path: '',
      breadcrumbName: '工艺细节',
    }]
  }, {
    path: '',
    breadcrumbName: '色彩中心',
    children: [{
      path: '',
      breadcrumbName: '色彩企划',
    }]
  }, {
    path: '',
    breadcrumbName: '灵感图库',
    children: [{
      path: '',
      breadcrumbName: 'Pinterest',
    }, {
      path: '',
      breadcrumbName: 'Behance',
    }, {
      path: '',
      breadcrumbName: 'Instagram',
    }, {
      path: '',
      breadcrumbName: '小红书',
    }]
  }, {
    path: '',
    breadcrumbName: '面料辅料',
    children: [{
      path: '',
      breadcrumbName: '面料',
    }, {
      path: '',
      breadcrumbName: '辅料',
    }]
  }, {
    path: '',
    breadcrumbName: '亦企智能'
  }
]
