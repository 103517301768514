
import {computed, defineComponent, PropType, ref} from 'vue'
import {storeToRefs} from 'pinia'
import {useMainStore, useSlidesStore} from '@/store'
import {ImageClipDataRange, ImageElementClip, PPTImageElement} from '@/types/slides'
import {ImageClipedEmitData} from '@/types/edit'
import {ContextmenuItem} from '@/components/Contextmenu/types'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useClipImage from './useClipImage'
import useFilter from './useFilter'
import ImageOutline from './ImageOutline/index.vue'
import ImageClipHandler from './ImageClipHandler.vue'
import {Modal} from 'ant-design-vue'
import NewCropEdit from '@/views/components/element/ImageElement/NewCropEdit.vue'

export default defineComponent({
  name: 'editable-element-image',
  components: {
    ImageOutline,
    ImageClipHandler,
    Modal,
    NewCropEdit
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTImageElement>,
      required: true,
    },
    selectElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTImageElement, canMove?: boolean) => void>,
      required: true,
    },
    contextmenus: {
      type: Function as PropType<() => ContextmenuItem[]>,
    },
  },
  setup(props) {
    const mainStore = useMainStore()
    const slidesStore = useSlidesStore()
    const {clipingImageElementId} = storeToRefs(mainStore)

    const {viewportRatio} = storeToRefs(slidesStore)
    const isCliping = computed(() => clipingImageElementId.value === props.elementInfo.id)

    const {addHistorySnapshot} = useHistorySnapshot()

    const shadow = computed(() => props.elementInfo.shadow)
    const {shadowStyle} = useElementShadow(shadow)

    const flipH = computed(() => props.elementInfo.flipH)
    const flipV = computed(() => props.elementInfo.flipV)
    const {flipStyle} = useElementFlip(flipH, flipV)

    const clip = computed(() => props.elementInfo.clip)
    const {clipShape, imgPosition, imgPosition2} = useClipImage(clip)

    const filters = computed(() => props.elementInfo.filters)
    const {filter} = useFilter(filters)
    const isImg = ref(false)
    const imgUrl = ref()
    const isCropping = ref(false)
    const newimgPosition = ref({
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    })
    const jieshou = (res: any) => {
      if (res.data.data) {
        imgUrl.value = res.data.data
        isImg.value = false
      }
    }
    const returnImgs = (data: ImageClipDataRange, src: string) => {

      handleClip2(data, src)
    }
    const imgEditableDBClick = () => {
      isImg.value = true
    }
    const closeIsImgDialog = () => {
      isImg.value = false
    }
    const handleSelectElement = (e: MouseEvent) => {
      if (props.elementInfo.lock) return
      e.stopPropagation()
      props.selectElement(e, props.elementInfo)
    }

    const handleClip2 = (data: ImageClipDataRange, src: string) => {
      mainStore.setClipingImageElementId('')
      if (!data) return
      const originClip: ImageElementClip = props.elementInfo.clip || {shape: 'rect', range: [[0, 0], [100, 100]]}
      const _props = {
        clip: {...originClip, range: data},
        src: src
      }
      slidesStore.updateElement({id: props.elementInfo.id, props: _props})
      isImg.value = false
      addHistorySnapshot()
    }
    const handleClip = (data: ImageClipedEmitData) => {
      mainStore.setClipingImageElementId('')

      if (!data) return

      const {range, position} = data
      const originClip: ImageElementClip = props.elementInfo.clip || {shape: 'rect', range: [[0, 0], [100, 100]]}
      const left = props.elementInfo.left + position.left
      const top = props.elementInfo.top + position.top
      const width = props.elementInfo.width + position.width
      const height = props.elementInfo.height + position.height

      let centerOffsetX = 0
      let centerOffsetY = 0

      if (props.elementInfo.rotate) {
        const centerX = (left + width / 2) - (props.elementInfo.left + props.elementInfo.width / 2)
        const centerY = -((top + height / 2) - (props.elementInfo.top + props.elementInfo.height / 2))

        const radian = -props.elementInfo.rotate * Math.PI / 180

        const rotatedCenterX = centerX * Math.cos(radian) - centerY * Math.sin(radian)
        const rotatedCenterY = centerX * Math.sin(radian) + centerY * Math.cos(radian)

        centerOffsetX = rotatedCenterX - centerX
        centerOffsetY = -(rotatedCenterY - centerY)
      }

      const _props = {
        clip: {...originClip, range},
        left: left + centerOffsetX,
        top: top + centerOffsetY,
        width,
        height,
      }
      slidesStore.updateElement({id: props.elementInfo.id, props: _props})

      addHistorySnapshot()
    }

    return {
      isCliping,
      handleClip,
      clipingImageElementId,
      shadowStyle,
      handleSelectElement,
      clipShape,
      imgPosition,
      filter,
      flipStyle,
      imgEditableDBClick,
      closeIsImgDialog,
      isImg,
      imgUrl,
      jieshou,
      returnImgs,
      imgPosition2,
      isCropping,
      newimgPosition,
    }
  },
})
