import {useMainStore, useSlidesStore} from '@/store'
import {DocumentList, Slide} from '@/types/slides'
import mockApi from '@/api/mockApi'
import {storeToRefs} from 'pinia'
import {VIEWPORT_SIZE} from '@/configs/canvas'
import {computed, ref} from 'vue'
import {message} from 'ant-design-vue'

export default () => {
  const slidesStore = useSlidesStore()
  const mainStore = useMainStore()
  const {slides, currentSlide, viewportRatio} = storeToRefs(slidesStore)
  const contentRef = ref<HTMLElement>()
  const canvasScale = computed(() => {
    if (!contentRef.value) return 1
    const contentWidth = contentRef.value.clientWidth
    const contentheight = contentRef.value.clientHeight

    const contentRatio = contentheight / contentWidth
    if (contentRatio >= viewportRatio.value) return (contentWidth - 20) / VIEWPORT_SIZE
    return (contentheight - 20) / viewportRatio.value / VIEWPORT_SIZE
  })

  /**
   * 获取所有文档
   */
  const getAllDocument = (): any => {
    mockApi().getAllDocument().then(res => {
      slidesStore.setdocumentList(res.data.documents)
      return res.data.documents
    })
  }

  const getDocumentId = (id: string, from: string): any => {
    mockApi().getDocumentId(id, from).then(res => {
      res.data.froms = from
      slidesStore.setDocuments(res.data)
      return res.data
    })
  }

  /**
   * 保存文档
   */
  const saveDocument = (isMessage: boolean) => {
    const mainStore = useMainStore()
    const {
      isAutoSave
    } = storeToRefs(mainStore)
    const key = 'updatable'
    if (isMessage) {
      message.loading({content: '保存中...', key})
    }
    const slidess = JSON.parse(JSON.stringify(slides.value))
    const data: any = {}
    data.id = slidesStore.documentsId
    data.title = slidesStore.documents?.title

    data.config = {
      background: '#fff',
      width: VIEWPORT_SIZE * canvasScale.value,
      height: VIEWPORT_SIZE * viewportRatio.value * canvasScale.value,
      pages: slidess
    }
    // 判断是否是if的企划，是就新建一个文档存储
    if (slidesStore.data_type) {
      mockApi().createDocument().then(res => {
        data.id = res.data.id
        mockApi().saveDocument(data).then(ress => {
          const ss = ress
          if (isMessage) {
            message.success({content: '已保存', key, duration: 2})
          }
        })
      })
    }
    else {
      mockApi().saveDocument(data).then(ress => {
        const ss = ress
        if (isMessage) {
          message.success({content: '已保存', key, duration: 2})
        }
      })
    }
  }

  const createDocument = () => {
    mockApi().createDocument().then(cres => {
      mockApi().myEditList().then(res => {
        if (res.data.list) {
          slidesStore.setdocumentList(res.data.list.length > 5 ? res.data.list.slice(0, 5) : res.data.list)
        }
      })
      message.success('创建成功')
      mainStore.setShowSelectDocumentList(false)
      slidesStore.setDocuments(getDocumentId(cres.data.id, ''))
    })
  }

  return {
    saveDocument,
    getAllDocument,
    getDocumentId,
    createDocument,
  }
}