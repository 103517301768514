import mockApi from '@/api/mockApi'

export default () => {

  const toUnicodeFun = (data: any) => {
    if (data === '' || typeof data === 'undefined') return ''
    let str = ''
    for (let i = 0; i < data.length; i++) {
      str += '\\u' + data.charCodeAt(i).toString(16)
    }
    return str
  }
  const searchGroup = (cname: string): any => {
    mockApi().searchGroup(cname).then(res => {
      return res.data.data
    })
  }


  return {
    searchGroup,
  }
}