import mockApi from '@/api/mockApi'

export default () => {


  const getQrcodeTicket = (): Promise<any> => {
    return new Promise(resolve => {
      mockApi().getQrcodeTicket().then(res => {
        if (res.data.ticket) {
          // 每秒更新一次时间
          resolve({
            ticket: res.data.ticket,
            qrcodeUrl: 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.ticket
          } as any)
          return {
            ticket: res.data.ticket,
            qrcodeUrl: 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.ticket
          }
        }
      })
    })
  }


  const verify = (ticket: string): Promise<any> => {
    return new Promise(resolve => {
      mockApi().verify(ticket).then(res => {
        // console.log('res', res)
        resolve(res as any)
      })
    })
  }

  /**
   * 获取用户信息
   */
  const getUserInfo = (): Promise<any> => {
    return new Promise(resolve => {
      mockApi().getUserInfo().then(res => {
        resolve(res as any)
      })
    })
  }

  return {
    getQrcodeTicket,
    verify,
    getUserInfo,
  }
}