// axiosAPI.js
// 导入axios
import axios, {AxiosResponse, AxiosError} from 'axios'
import Cookies from 'js-cookie'
import {useMainStore} from '@/store'
import {message} from 'ant-design-vue'

// 使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
const API = axios.create({
  baseURL: 'https://design.iftrend.com', // 请求后端数据的基本地址，自定义
  timeout: 30000, // 请求超时设置，单位ms
})
// API.interceptors.response.use(
//   (response: AxiosResponse) => {
//     // 在请求完成后做一些处理，比如获取Cookie并保存到本地
//     const cookieHeader = response.headers['set-cookie']
//     if (cookieHeader) {
//       // 如果是字符串数组形式，则直接添加新的Cookie
//       if (Array.isArray(cookieHeader)) {
//         cookieHeader.push('new_cookie=new_value')
//         // 将修改后的Cookie数组设置回响应头
//         response.headers['set-cookie'] = cookieHeader
//       }
//       else {
//         // 如果是字符串形式，则将其转为字符串数组再添加新的Cookie
//         const cookies = [cookieHeader, 'new_cookie=new_value']
//         // 将修改后的Cookie数组设置回响应头
//         response.headers['set-cookie'] = cookies
//       }
//     }
//     return response
//   },
//   // …
// )
// 请求拦截器
// API.interceptors.request.use(
//   (config) => {
//     // 在发送请求之前做一些处理，如添加token等
//     config.headers.Authorization = 'Bearer your_token'
//
//     return config
//   },
//   (error) => {
//     // 请求错误时的处理
//     return Promise.reject(error)
//   }
// )

// 响应拦截器
API.interceptors.response.use(
  (response) => {
    // 对接收到的响应进行处理，如处理错误码等
    if (response.data.status === 'fail' || response.data.reason === 'no_loged_in') {
      message.warn('账号登录状态失效，请重新登录！')
      const mainStore = useMainStore()
      const setIsLoginDialog = mainStore.setIsLoginDialog
      const setLoginStatus = mainStore.setLoginStatus
      const setIndexShow = mainStore.setIndexShow
      setIsLoginDialog(true)
      setLoginStatus(false)
      Cookies.remove('session_key')
      Cookies.remove('userinfo')
    }
    // if(response.)
    return response
  },
  (error) => {
    // 对响应错误进行处理
    return Promise.reject(error)
  }
)

// 导出我们建立的axios实例模块，ES6 export用法
export default API