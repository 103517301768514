import http from '@/api/http'
import {Slide} from '@/types/slides'
import {Ref} from 'vue'
import md5 from 'md5'
import axios from 'axios'
import http2 from '@/api/http2'
import Cookies from 'js-cookie'

export default () => {

  // 用户列表
  const findAll = () => {
    return http({
      url: `/api/mockGetList`,
      method: 'get',
    })
  }
  // 添加用户
  const addUser = ({user}: { user: any }) => {
    {
      return http({
        url: `/api/add`,
        method: 'post',
        data: user,
      })
    }
  }


  /**
   * 根据id查文档
   * @param id
   */
  const getDocumentId = (id: string, from: string) => {
    {
      return http({
        url: `/api/document?id=` + id + `&from=` + from,
        method: 'get',
      })
    }
  }

  /**
   * 获取所有文档
   */
  const getAllDocument = () => {
    {
      return http({
        url: `/api/document/all`,
        method: 'get',
      })
    }
  }

  /**
   * 上传图片 --> 服务器
   * @param id 文档id
   * @param file 上传域
   */
  const uploadImg = (id: string, file: File) => {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('file1', file)
    {
      return http({
        url: `/api/image/upload`,
        method: 'post',
        data: formData,
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 提交抠图任务
   * @param pic
   */
  const addTask = (pic: string) => {
    const formData = new FormData()
    const timestamp = parseInt(String(new Date().getTime() / 1000)) + ''
    const token = md5(md5('ZHONG8982ZHENG792JDSLDSHAL==') + timestamp + pic)
    formData.append('pic', pic)
    formData.append('time', timestamp)
    formData.append('token', token)
    {
      return http({
        url: `/api/matting/addTask?` + `pic=` + encodeURIComponent(pic) + `&time=` + timestamp + `&token=` + token,
        method: 'get',
      })
    }
  }

  /**
   * 提交任务后，根据获取的任务id
   * @param id
   */
  const taskResult = (id: string) => {
    const formData = new FormData()
    const timestamp = parseInt(String(new Date().getTime() / 1000)) + ''
    const token = md5(md5('ZHONG8982ZHENG792JDSLDSHAL==') + timestamp + id)
    formData.append('id', id)
    formData.append('time', timestamp.toString())
    formData.append('token', token)
    {
      return http({
        url: `/api/matting/taskresult?` + `id=` + id + `&time=` + timestamp + `&token=` + token,
        method: 'get'
      })
    }
  }

  /**
   * 获取ticket
   */
  const getQrcodeTicket = () => {
    {
      return http({
        url: `/api/wechat/qrcode`,
        method: 'get'
      })
    }
  }

  /**
   * 通过ticket获取二维码
   * @param ticket
   */
  const verify = (ticket: string) => {
    {
      return http({
        url: `/api/wechat/verify?ticket=` + ticket,
        method: 'get'
      })
    }
  }

  /**
   * 最新图片
   */
  const imageNewList = (key: string, page: number) => {
    {
      return http({
        url: `/api/image/newlist?key=` + key + `&page=` + page,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }


  /**
   * 最新企划
   */
  const newListDocument = (key: string) => {
    {
      return http({
        url: `/api/document/newlist?key=` + key,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 推荐企划
   */
  const recommend = () => {
    {
      return http({
        url: `/api/document/recommend`,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }
  /**
   * 企划搜索
   * @param key
   * @param page
   */
  const searchDocument = (key: string, page: number) => {
    {
      return http({
        url: `/api/document/search?key=` + key + `&page=` + page,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  const searchGroup = (cname: string,) => {
    {
      return http({
        url: `/api/data/search_group?cname=` + cname,
        method: 'get'
      })
    }
  }
  /**
   * 查询Img
   * @param key
   * @param page
   */
  const searchImg = (key: string, page: number) => {
    {
      return http({
        url: `/api/image/search?key=` + key + `&page=` + page,
        method: 'get'
      })
    }
  }


  /**
   * 保存色卡
   * @param data
   */
  const colorSave = (data: any) => {
    {
      return http.post(`/api/color/save`, data, {
        method: 'POST',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 获取我的色卡
   */
  const getMyColors = () => {
    {
      return http({
        url: `/api/color/read`,
        method: 'POST',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 删除色卡
   * @param id
   */
  const deleteColor = (id: string) => {
    {
      return http({
        url: `/api/color/delete?` + `id=` + id,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 根据筛选项获取数据（图片/企划)
   * @param data
   * @param page
   * @param dt 图片/企划 picture,report
   */
  const filter = (data: any, page: any, rank: string, dt: string) => {
    {
      return http.post(`/api/data/filter?page=` + page + `&rank=` + rank + `&dt=` + dt, data, {
        method: 'POST'
      })
    }
  }

  /**
   * 获取文件夹功能
   */
  const getFolder = () => {
    {
      return http({
        url: `/api/favorite/folder`,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 新建文件夹
   * @param name
   */
  const addFolder = (name: string) => {
    {
      return http.post(`/api/favorite/folderadd`, `name=` + name, {
        method: 'POST',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 删除文件夹
   * @param id
   */
  const deleteFolder = (id: string) => {
    {
      return http({
        url: `/api/favorite/folderdelete?fid=` + id,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }
  const deleteFolderAll = (fids: string) => {
    {
      return http.post(`/api/favorite/folderdelete`, `fids=` + fids, {
        method: 'POST',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   *
   * @param fid 文件夹id
   * @param data_source 文件来源 iftrend or design
   * @param data_type 文件类型 document企划 or picture图片
   * @param data_id 文件id
   * @param pic 图片地址 转unicode码
   */
  const addFavorite = (fid: string, data_source: string, data_type: string, data_id: string, pic: string | null) => {
    {
      return http({
        url: `/api/favorite/add?fid=` + fid + `&data_source=` + data_source + `&data_type=` + data_type + `&data_id=` + data_id + `&pic=` + pic,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 拉取收藏夹
   * @param fid
   */
  const getFavoriteData = (fid: string, data_type: string) => {
    {
      return http({
        url: `/api/favorite/data?fid=` + fid + `&data_type=` + data_type,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 删除收藏
   * @param id
   */
  const deleteFavorite = (id: string) => {
    {
      return http({
        url: `/api/favorite/delete?id=` + id,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  const deleteFavoriteAll = (ids: string) => {
    {
      return http.post(`/api/favorite/delete`, `ids=` + ids, {
        method: 'POST',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }
  /**
   * 我的企划
   */
  const myEditList = () => {
    {
      return http({
        url: `/api/document/myeditlist`,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 保存文档
   * @param slide
   */
  const saveDocument = (slide: any) => {
    {
      return http({
        url: `/api/document/save`,
        method: 'post',
        data: slide,
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 新建文档
   */
  const createDocument = () => {
    {
      return http({
        url: `/api/document/create`,
        method: 'POST',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 获取用户信息
   */
  const getUserInfo = () => {
    {
      return http({
        url: `/api/user/profile`,
        method: 'get',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  /**
   * 保存个人信息
   * @param data
   */
  const saveProfile = (data: any) => {
    {
      return http.post(`/api/user/saveprofile`, data, {
        method: 'POST',
        headers: {
          token: Cookies.get('session_key')
        }
      })
    }
  }

  return {
    findAll,
    addUser,
    saveDocument,
    getAllDocument,
    getDocumentId,
    uploadImg,
    addTask,
    taskResult,
    createDocument,
    getQrcodeTicket,
    verify,
    imageNewList,
    myEditList,
    newListDocument,
    recommend,
    searchDocument,
    searchGroup,
    searchImg,
    colorSave,
    filter,
    getMyColors,
    deleteColor,
    getFolder,
    addFolder,
    deleteFolder,
    addFavorite,
    getFavoriteData,
    deleteFavorite,
    getUserInfo,
    saveProfile,
    deleteFavoriteAll,
    deleteFolderAll
  }
}