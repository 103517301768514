import mockApi from '@/api/mockApi'
import {useSlidesStore} from '@/store'
import {storeToRefs} from 'pinia'
import useAddSlidesOrElements from '@/hooks/useAddSlidesOrElements'
import {ref} from 'vue'
import {message} from 'ant-design-vue'

interface ImageSize {
  width: number
  height: number
}

export const working = ref(false)

/**
 * 获取图片的原始宽高
 * @param src 图片地址
 */
export const getImageSize = (src: string): Promise<ImageSize> => {
  return new Promise(resolve => {
    const img = document.createElement('img')
    img.src = src
    img.style.opacity = '0'
    document.body.appendChild(img)

    img.onload = () => {
      const imgWidth = img.clientWidth
      const imgHeight = img.clientHeight

      img.onload = null
      img.onerror = null

      document.body.removeChild(img)

      resolve({width: imgWidth, height: imgHeight})
    }

    img.onerror = () => {
      img.onload = null
      img.onerror = null
    }
  })
}

/**
 * 读取图片文件的dataURL
 * @param file 图片文件
 */
export const getImageDataURL = (file: File): Promise<string> => {
  return new Promise(resolve => {
    // const reader = new FileReader()
    // reader.addEventListener('load', () => {
    //   resolve(reader.result as string)
    // })
    mockApi().uploadImg('', file).then(res => {
      if (res.data.code === 200) {
        resolve(res.data.data as string)
      }
    }).catch((err: any) => {
      console.log(err, 'err')
    })
    // reader.readAsDataURL(file)
  })
}


export const taskResult = (id: any): Promise<any> => {
  return new Promise(resolve => {
    mockApi().taskResult(id).then(res => {
      resolve(res.data as any)
    })

  })
}

export const addTask = (url: any): Promise<string> => {
  return new Promise(resolve => {
    mockApi().addTask(url).then(res => {
      if (res.data.status === 'success') {
        // 每秒更新一次时间
        const si = setInterval(() => {
          taskResult(res.data.id).then(data => {
            if (data.status === 'success') {
              clearInterval(si)
              resolve(data.pic as any)
              return data.pic
            }
          })
        }, 500)
      }
    })
  })
}


export const getUploadImg = (id: any, file: File): Promise<string> => {
  return new Promise(resolve => {
    working.value = true
    mockApi().uploadImg(id, file).then(res => {
      addTask(res.data.data).then(data => {
        working.value = false
        resolve(data as string)
      })
    })
  })
}
