import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
import './registerServiceWorker'

import Ant from 'ant-design-vue/es'
import 'ant-design-vue/dist/antd.less'

import '@icon-park/vue-next/styles/index.css'
import 'prosemirror-view/style/prosemirror.css'
import 'animate.css'

import '@/assets/styles/prosemirror.scss'
import '@/assets/styles/antd.scss'
import '@/assets/styles/font.scss'
import '@/assets/styles/global.scss'
import '@/assets/styles/font2.scss'

import Icon from '@/plugins/icon'
import Directive from '@/plugins/directive'
import * as ANTDIcon from '@ant-design/icons-vue'
import {VueMasonryPlugin} from 'vue-masonry'
import Drag from './utils/drag'
import router from '@/router'
const app = createApp(App)
app.use(router)
app.use(Icon)
app.use(Directive)
app.use(createPinia())
app.use(Ant)
app.use(VueMasonryPlugin)
app.use(Drag)
Object.keys(ANTDIcon).forEach((key) => {
  app.component(key, ANTDIcon[key])
})
app.provide('$routeData', {})
app.mount('#app')